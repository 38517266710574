export interface ListMostSellingDB {
    id : string,
    brand: string;
    code: string,
    product_name: string,
    price: string,
    priority: string,
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTMOSTSELLINGDB: ListMostSellingDB[] = [
    {
        id: "1",
        brand:"Honeywell",
        code:"0077",
        product_name:"T-Shirts",
        price:"175000",
        priority:"2",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        id: "2",
        brand:"Honeywell2",
        code:"0028",
        product_name:"Jens",
        price:"1530",
        priority:"7",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
