import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { CustomersComponent } from './customers/customers.component';
import { ViewOrdersComponent } from './view_orders/view_orders.component';
import { CancelOrdersComponent } from './cancel_orders/cancel_orders.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'orders',
        component: OrdersComponent,
        data: {
          title: "Orders",
          breadcrumb: "Orders"
        }
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: "Transactions",
          breadcrumb: "Transactions"
        }
      },
      {
        path: 'customers',
        component: CustomersComponent,
        data: {
          title: "Customers",
          breadcrumb: "Customers"
        }
      },
      {
        path: 'view-orders',
        component: ViewOrdersComponent,
        data: {
          title: "View Orders",
          breadcrumb: "View Orders"
        }
      },
      {
        path: 'cancel-orders',
        component: CancelOrdersComponent,
        data: {
          title: "Cancel Orders",
          breadcrumb: "Cancel Orders"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
