<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input type="text" class="form-control" id="id" formControlName="id" required>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id"><small>Auto Generated</small></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Name <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="name" formControlName="name" required="" [class.invalid]="formGroup.get('name').invalid && formGroup.get('name').touched">
                                <div class="error-message" *ngIf="formGroup.get('name').hasError('required') && formGroup.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="title">Meta Title</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="title" formControlName="title" required="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="keyword">Meta Keyword</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="keyword" formControlName="keyword" required="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="description">Meta Description</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="description" formControlName="description" required="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="position" class="mb-0">Image Position</label>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-9 col-sm-8">
                                <select class="form-control form-select" id="position" formControlName="position" [(ngModel)]="selectedValue" (change)="onSelectionChange($event.target.value)" [class.invalid]="formGroup.get('position').invalid && formGroup.get('position').touched" aria-label="Default select example">
                                    <option value="0" selected>Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <div class="error-message" *ngIf="formGroup.get('position').hasError('required') && formGroup.get('position').touched">
                                    Type is required.
                                </div>
                                <div class="error-message" *ngIf="formGroup.get('position').hasError('invalidSelect') && formGroup.get('position').touched">
                                    Type select Location.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="redirection_url">Redirection Url</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="redirection_url" formControlName="redirection_url">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="add-product">
                                    <ul class="file-upload-product">
                                        <li>
                                            <div *ngIf="selectedValue == 1" class="box-input-file image-1">
                                                <input class="upload image-1" type="file" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-1" alt="" [src]="image1">
                                            </div>
                                            <div *ngIf="selectedValue == 2" class="box-input-file image-2">
                                                <input class="upload image-2" type="file" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-2" alt="" [src]="image2">
                                            </div>
                                            <div *ngIf="selectedValue == 3" class="box-input-file image-3">
                                                <input class="upload image-3" type="file" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-3" alt="" [src]="image3">
                                            </div>
                                            <div *ngIf="selectedValue == 4" class="box-input-file image-3">
                                                <input class="upload image-3" type="file" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-3" alt="" [src]="image4">
                                            </div>
                                            <div *ngIf="selectedValue == 5" class="box-input-file image-2">
                                                <input class="upload image-2" type="file" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-2" alt="" [src]="image5">
                                            </div>
                                            <input class="form-control" type="hidden" formControlName="image_url">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="isactive" formControlName="isactive" data-original-title="" title="">
                                    <label for="isactive">Active</label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="isNameControlEmpty()">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->