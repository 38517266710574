<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>List Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="custom-datatable">
                        <button class="btn btn-danger me-1 mb-3" style="float: right;" (click)="deleteRecord()">Delete</button>
                        <button class="btn btn-secondary me-1 mb-3" style="float: right;" (click)="editRecord()">Edit</button>
                        <button class="btn btn-primary me-1 mb-3" style="float: right;" [routerLink]="'/settings/create-banner'">Add</button>
                        <div class="clearfix"></div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <!-- <th scope="col" sortable="title" (sort)="onSort($event)">Id</th> -->
                                    <th scope="col" sortable="code" (sort)="onSort($event)">Page Name</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Location</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Type</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="this.listData.length > 0; else noData">
                                    <tr *ngFor="let item of this.listData">
                                        <th scope="row">
                                            <input type="checkbox" name="title_select" (change)="onSelect(item.Id, $event)">
                                            {{ item.PageId === 1 ? 'Home' : 'Category' }}
                                        </th>
                                        <td>
                                            {{ item.PlacementId === 1 ? 'Top Banner' : 'Middle Banner' }}
                                        </td>
                                        <td>
                                            {{ item.Type === 1 ? 'Image' : 'Video' }}
                                        </td>
                                        <td>
                                            <span class='badge {{ item.IsActive === 1 ? "badge-success" : "badge-danger" }}'>{{ item.IsActive === 1 ? 'Active' : 'Inactive' }}</span>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #noData>
                                    <tr class="text-center">
                                        <td colspan="5">No data available</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->