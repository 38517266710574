export interface ListCountriesDB {
    id:string;
    code:string;
    state_name:string;
    name: string;
    city_name: string;
    created_by:string;
    created_on:string;
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTCOUNTRIESDB: ListCountriesDB[] = [
    {
        id: "1",
        code:"PK",
        name: " Pakistan",
        city_name: "Karachi",
        state_name:"Sindh",
        created_by : "admin",
        created_on : "13-Apr-2020",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        id: "2",
        code:"AUS",
        name: " Australia",
        state_name:"Tasmania",
        city_name: "Karachi",
        created_by : "admin",
        created_on : "15-Apr-2020",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
