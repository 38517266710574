import { Component, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ViewChild, ElementRef } from '@angular/core';
import { CatalogService } from '../catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})

export class EditProductComponent implements OnInit {

  public user = localStorage.getItem('user');

  id: number;
  public Editor = ClassicEditor;
  public categoryList = [];
  colorList = [];
  colorSettings: IDropdownSettings = {};
  selectedColors = [];
  getSelectedColors = [];
  sizeList = [];
  sizeSettings: IDropdownSettings = {};
  selectedSizes = [];
  getSelectedSizes = [];

  formGroup: FormGroup;
  formData: FormData = new FormData();
  
  constructor(
    private formBuilder: FormBuilder, 
    private apiService: CatalogService, 
    private alertService: AlertService, 
    private router: Router, 
    private route: ActivatedRoute
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    console.log("this.files :", this.files);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    //Color
    this.colorList = [
      { item_id: 1, item_text: 'Black' },
      { item_id: 2, item_text: 'Blue' },
      { item_id: 3, item_text: 'Brown' },
      { item_id: 4, item_text: 'Gray' },
      { item_id: 5, item_text: 'Green' },
      { item_id: 6, item_text: 'Orange' },
      { item_id: 7, item_text: 'Pink' },
      { item_id: 8, item_text: 'Purple' },
      { item_id: 9, item_text: 'Red' },
      { item_id: 10, item_text: 'White' },
      { item_id: 11, item_text: 'Yellow' },
    ];

    this.colorSettings = {
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      allowSearchFilter: true
    };

    //Size
    this.sizeList = [
      { item_id: 1, item_text: 'Small' },
      { item_id: 2, item_text: 'Medium' },
      { item_id: 3, item_text: 'Large' }
    ];

    this.sizeSettings = {
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false
    };

    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      code: [''],
      name: ['', [Validators.required]],
      category: [0, Validators.min(1)],
      price: [0, [Validators.required]],
      discount: [0],
      colors: [this.selectedColors],
      sizes: [this.selectedSizes],
      description: [''],
      featured: [false],
      top_rated: [false],
      stock: [true],
      isactive: [true]
    });

    this.id = +this.route.snapshot.queryParamMap.get('id');

    this.apiService.getProductById(this.id).subscribe(
      (data) => {
        console.log('API Edit Response:', data);

        const colorArray = data.Colors.split(',');
        const sizeArray = data.Sizes.split(',');

        colorArray.forEach(element => {
          const color = this.colorList.find(item => item.item_text === element);
          if (color) {
            this.selectedColors.push(color.item_text);
            this.getSelectedColors.push(color);
          }
        });

        sizeArray.forEach(element => {
          const size = this.sizeList.find(item => item.item_text === element);
          if (size) {
            this.selectedSizes.push(size.item_text);
            this.getSelectedSizes.push(size);
          }
        });

        // Handle the data as needed
        this.formGroup.patchValue({
          id: data.Id,
          code: data.Code,
          name: data.Name,
          category: data.CategoryId,
          price: data.Price,
          discount: data.Discount,
          colors: this.getSelectedColors,
          sizes: this.getSelectedSizes,
          description: data.Description,
          image_url: data.ImageURL,
          featured: (data.IsFeatured === 1 ? true : false),
          top_rated: (data.IsTopRated === 1 ? true : false),
          stock: (data.IsStock === 1 ? true : false),
          isactive: (data.IsActive === 1 ? true : false)
        });

        this.files = data.images.map(imageUrl => {
          return new File([environment.apiUrl + '/uploads/product/'], imageUrl.Image, { type: imageUrl.Type });
        });
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );

    this.apiService.getActiveCategoriesData().subscribe(
      (data) => {
        // console.log('data:', data);
        this.categoryList = data;
      },
      (error) => {
        console.error('API Category Error:', error);
      }
    );
  }

  async onSubmit() {
    if (this.formGroup.valid) {
      this.formData.append('id', this.formGroup.get('id').value);
      this.formData.append('code', this.formGroup.get('code').value);
      this.formData.append('name', this.formGroup.get('name').value);
      this.formData.append('category', this.formGroup.get('category').value);
      this.formData.append('brand', '1');
      this.formData.append('price', this.formGroup.get('price').value);
      this.formData.append('discount', this.formGroup.get('discount').value);
      this.formData.append('colors', this.selectedColors.join(','));
      this.formData.append('sizes', this.selectedSizes.join(','));
      this.formData.append('description', this.formGroup.get('description').value);
      this.formData.append('featured', this.formGroup.get('featured').value);
      this.formData.append('top_rated', this.formGroup.get('top_rated').value);
      this.formData.append('stock', this.formGroup.get('stock').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      // Append each file to the FormData object
      this.files.forEach(file => {
        this.formData.append('files', file, file.name);
      });

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.apiService.postProductData(this.formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record saved successfully');

        this.router.navigate(['/catalog/list-product']);

        setTimeout(() => {
          this.alertService.setShowAlert(false);
        }, 1500);
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }

  onColorSelect(item: any) {
    console.log("this.selectedColors before adding :", this.selectedColors);
    this.selectedColors.push(item.item_text);
    console.log("this.selectedColors after adding :", this.selectedColors);
  }

  onColorDeSelect(item: any) {
    this.selectedColors = this.selectedColors.filter(color => color !== item.item_text);
  }

  onSizeSelect(item: any) {
    this.selectedSizes.push(item.item_text);
  }

  onSizeDeSelect(item: any) {
    this.selectedSizes = this.selectedSizes.filter(size => size !== item.item_text);
  }

}
