<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>List Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="custom-datatable">

                        <button class="btn btn-danger me-1 mb-3" style="float: right;">Delete</button>
                        <button class="btn btn-secondary me-1 mb-3" style="float: right;">Edit</button>
                        <button class="btn btn-primary me-1 mb-3" [routerLink]="'/settings/create-city'"
                            style="float: right;">Add</button>
                        <div class="clearfix"></div>
                        <div class="panel panel-default panel-filter" >
                            <div class="panel-heading">
                                <h3 class="panel-title" style="display: inline-block;">Filters</h3>
                            </div>
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                           
                                            <div class="col-sm-6 d-flex">
                                                <label class="col-md-2 col-form-label" for="ddlCountrySearch">Country</label>
                                                <div class="col-md-10">
                                                    <select id="ddlCountrySearch" name="ddlCountrySearch" class="form-control">
                                                        <option class="text-gray" value="0" selected="selected">Select value</option>
                                                        <option value="166">Pakistan</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 d-flex">
                                                <label class="col-md-2 col-form-label" for="ddlCountrySearch">State</label>
                                                <div class="col-md-10">
                                                    <select id="ddlCountrySearch" name="ddlCountrySearch" class="form-control">
                                                        <option class="text-gray" value="0" selected="selected">Select value</option>
                                                        <!-- <option value="166">Karachi</option> -->
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="row mt-3">
                                    <div class="col-sm-12">
                                        <div class="btn-group">
                                            <a href="#" id="btnApplyFilter" class="btn btn-sm btn-success">Fetch State</a>&nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group row mt-5">
                            <div class="col-xl-6 col-lg-3 col-md-3 col-sm-4 d-flex">
                              
                                    <label for="validationCustom0">Search :</label>
                                    <input class="form-control ml-5" style="margin-left: 15px;" id="validationCustom0" type="text">
                            </div>


                        </div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="title" (sort)="onSort($event)">Id</th>
                                    <th scope="col" sortable="code" (sort)="onSort($event)">Code</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">City Name</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Country</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">State</th>
                                 
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Created By</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Create On</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tableItem$ | async">
                                    <th scope="row">
                                        {{ item.id }}
                                    </th>
                                    <td>
                                        {{item.code}}
                                    </td>
                                    <td>
                                        {{item.city_name}}
                                    </td>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{item.state_name}}
                                    </td>
                                   
                                    <td>
                                        {{item.created_by}}
                                    </td>
                                    <td>{{item.created_on}}</td>

                                    <td>
                                        <span class='badge {{item.payment_class}}'>{{ item.status }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->