<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0">Category <span>*</span></label>
                            </div>
                            <div class="col-xl-8 col-lg-3 col-md-3 col-sm-4">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select Category</option>
                                    <option value="1">Shirts</option>
                                    <!-- <option value="2">Intel</option>
                                    <option value="3">POSPRO</option> -->
                                </select>                            </div>
                            <!-- <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0"><small>Auto Generated</small></label>
                            </div> -->
                        </div>
                        <!-- <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Sub Category <span>*</span></label>
                            </div>
                            <div class="col-xl-8 col-lg-9 col-md-9 col-sm-8">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select Brand</option>
                                    <option value="1">HP</option>
                                    <option value="2">Intel</option>
                                    <option value="3">POSPRO</option>
                                </select>    
                                <div class="error-message"
                                    *ngIf="form.get('name').hasError('required') && form.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Product <span>*</span></label>
                            </div>
                            <div class="col-xl-8 col-lg-9 col-md-9 col-sm-8">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select Products</option>
                                    <option value="1">Red Shirt</option>
                                    <!-- <option value="2">Intel</option>
                                    <option value="3">POSPRO</option> -->
                                </select>    
                                <div class="error-message"
                                    *ngIf="form.get('name').hasError('required') && form.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Priority <span>*</span></label>
                            </div>
                            <div class="col-xl-8 col-lg-9 col-md-9 col-sm-8">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select Priority</option>
                                    <option value="1">3</option>
                                    <option value="2">4</option>
                                    <option value="3">7</option>
                                </select>    
                                <div class="error-message"
                                    *ngIf="form.get('name').hasError('required') && form.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="isNameControlEmpty()">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->