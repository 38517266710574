import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CatalogService {
  //private apiUrl = 'http://localhost:3000'; // replace with your API endpoint
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // Start Category
  getCategoriesData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/allCategories`);
  }

  getActiveCategoriesData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/activeCategories`);
  }

  postCategoryData(formData: FormData): Observable<any> {
    console.log("Service Data :", formData);

    const headers = new HttpHeaders();

    return this.http.post(`${this.apiUrl}/saveCategory`, formData, { headers });
  }

  getCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/categories/${id}`);
  }

  deleteCategory(ids: number[]): Observable<any> {
    console.log("delete service ids :", ids);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ids: ids } // Pass an object with 'ids' key containing the array of category IDs
    };
    return this.http.delete<any>(`${this.apiUrl}/deleteCategory`, options);
  }
  // End Category

  //Start Product
  getProductsData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/allProducts`);
  }

  getActiveProductsData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/activeProducts`);
  }

  postProductData(formData: FormData): Observable<any> {
    console.log("Service Data :", formData);

    const headers = new HttpHeaders();

    return this.http.post(`${this.apiUrl}/saveProduct`, formData, { headers });
  }

  getProductById(id): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/products/${id}`);
  }

  deleteProduct(ids: number[]): Observable<any> {
    console.log("delete service ids :", ids);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ids: ids } // Pass an object with 'ids' key containing the array of category IDs
    };
    return this.http.delete<any>(`${this.apiUrl}/deleteProduct`, options);
  }
  //End Product

  postData(formData: FormData): Observable<any> {
    console.log("Service Data :", formData);

    const headers = new HttpHeaders();

    return this.http.post(`${this.apiUrl}/saveCategory`, formData, { headers });
  }
}
