import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from '../catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})

export class CreateCategoryComponent implements OnInit {

  public user = localStorage.getItem('user');

  public image1 = "assets/images/category/placeholder-image-1.jpg";
  public image2 = "assets/images/category/placeholder-image-2.jpg";
  public image3 = "assets/images/category/placeholder-image-3.jpg";
  public image4 = "assets/images/category/placeholder-image-3.jpg";
  public image5 = "assets/images/category/placeholder-image-2.jpg";

  selectedValue: any = 0;
  formData: FormData = new FormData();
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder, 
    private catalogService: CatalogService, 
    private alertService: AlertService, 
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    // initialize the form with form controls and validators
    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      name: ['', [Validators.required]],
      title: [''],
      keyword: [''],
      description: [''],
      position: [0],
      redirection_url: [''],
      isactive: [true]
    });
  }

  onSelectionChange(selectedDiv: string): void {
    this.selectedValue = parseInt(selectedDiv);
    console.log('Selected value:', this.selectedValue);
  }
  
  async onSubmit() {
    if (this.formGroup.valid) {
      this.formData.append('id', '0');
      this.formData.append('name', this.formGroup.get('name').value);
      this.formData.append('title', this.formGroup.get('title').value);
      this.formData.append('keyword', this.formGroup.get('keyword').value);
      this.formData.append('description', this.formGroup.get('description').value);
      this.formData.append('position', this.formGroup.get('position').value);
      this.formData.append('redirection_url', this.formGroup.get('redirection_url').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.catalogService.postCategoryData(this.formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record saved successfully');

        this.router.navigate(['/catalog/list-category']);

        setTimeout(() => {
          this.alertService.setShowAlert(false);
        }, 1500);
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }

  readFileUrl(event: any) {
    const file: File = event.target.files[0]; // Get the selected file
    const reader = new FileReader();
    
    if (event.target.files.length === 0)
      return;

    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      if (this.selectedValue === 1) {
        this.image1 = reader.result.toString();
      } 
      else if (this.selectedValue === 2) {
        this.image2 = reader.result.toString();
      }
      else if (this.selectedValue === 3) {
        this.image3 = reader.result.toString();
      }
      else if (this.selectedValue === 4) {
        this.image4 = reader.result.toString();
      }
      else if (this.selectedValue === 5) {
        this.image5 = reader.result.toString();
      }
    }

    // Add the file to your FormData object
    if (file) {
      //this.formData = new FormData();
      this.formData.append('myFile', file);
    }
  }
}
