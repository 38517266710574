import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
import { title } from 'process';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Catalog', icon: 'box', type: 'sub', active: false, children: [
				{
					title: 'Category', type: 'sub', children: [
						{ path: '/catalog/list-category', title: 'List Categories', type: 'link' },
						{ path: '/catalog/create-category', title: 'Create Category', type: 'link' }
					]
				},
				// {
				// 	title: 'Brands', type: 'sub', children: [
				// 		{ path: '/catalog/list-brand', title: 'List Brands', type: 'link' },
				// 		{ path: '/catalog/create-brand', title: 'Create Brand', type: 'link' }
				// 	]
				// },
				// {
				// 	title: 'Attributes', type: 'sub', children: [
				// 		{ path: '/catalog/list-attribute', title: 'List Attributes', type: 'link' },
				// 		{ path: '/catalog/create-attribute', title: 'Create Attribute', type: 'link' }
				// 	]
				// },
				// {
				// 	title: 'Specifications', type: 'sub', children: [
				// 		{ path: '/catalog/list-specification', title: 'List Specifications', type: 'link' },
				// 		{ path: '/catalog/create-specification', title: 'Create Specification', type: 'link' }
				// 	]
				// },
				{
					title: 'Products', type: 'sub', children: [
						{ path: '/catalog/list-product', title: 'List Products', type: 'link' },
						{ path: '/catalog/create-product', title: 'Create Product', type: 'link' }
					]
				},
				// {
				// 	title: 'Most Selling Product', type: 'sub', children: [
				// 		{ path: '/catalog/list-most-selling', title: 'List Most Selling', type: 'link' },
				// 		{ path: '/catalog/create-most-selling', title: 'Create Most Selling', type: 'link' }
				// 	]
				// }
			]
		},
		{
			title: 'Customers', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/sales/customers', title: 'Customers', type: 'link' },
				{ path: '/sales/transactions', title: 'Payments', type: 'link' },
			]
		},
		{
			title: 'Orders', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/sales/view-orders', title: 'View Orders', type: 'link' },
				{ path: '/sales/cancel-orders', title: 'Cancel Orders', type: 'link' },
			]
		},
		{
			title: 'Blogs', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/blogs/list-blog', title: 'List Blogs', type: 'link' },
				{ path: '/blogs/create-blog', title: 'Create Blog', type: 'link' },
			]
		},
		{
			title: 'Settings', icon: 'settings', type: 'sub', active: false, children: [
				{
					title: 'Country', type: 'sub', children: [
						{ path: '/settings/list-countries', title: 'List Countries', type: 'link' },
						{ path: '/settings/create-country', title: 'Create Country', type: 'link' }
					]
				},
				{
					title: 'States', type: 'sub', children: [
						{ path: '/settings/list-state', title: 'List States', type: 'link' },
						{ path: '/settings/create-state', title: 'Create State', type: 'link' }
					]
				},
				{
					title: 'City', type: 'sub', children: [
						{ path: '/settings/list-city', title: 'List Cities', type: 'link' },
						{ path: '/settings/create-city', title: 'Create City', type: 'link' }
					]
				},
				{
					title: 'Banners', type: 'sub', children: [
						{ path: '/settings/list-banners', title: 'List Banners', type: 'link' },
						{ path: '/settings/create-banner', title: 'Create Banner', type: 'link' }
					]
				},
				{
					title: 'Sliders', type: 'sub', children: [
						{ path: '/settings/list-sliders', title: 'List Sliders', type: 'link' },
						{ path: '/settings/create-sliders', title: 'Create Slider', type: 'link' }
					]
				},
				{
					title: 'Delivery Charges', type: 'sub', children: [
						{ path: '/settings/list-delivery_charges', title: 'List Charges', type: 'link' },
						{ path: '/settings/create-delivery_charges', title: 'Create Charges', type: 'link' }
					]
				},
				{
					title: 'Payment Methods', type: 'sub', children: [
						{ path: '/settings/list-payment_methods', title: 'List Payment Methods', type: 'link' },
						{ path: '/settings/create-payment_methods', title: 'Create Payment Method', type: 'link' }
					]
				},
					{ path: '/settings/list-sms_history', title: 'SMS', type: 'link' },
			]
		},
		{
			path: '/settings/list-sms_history', title: 'SMS', icon: 'message-square', type: 'link'
		},
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
