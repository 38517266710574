<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input class="form-control" id="validationCustom0" type="text" disabled required="">
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0"><small>Auto Generated</small></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Name <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="name" formControlName="name" required="" [class.invalid]="categoryForm.get('name').invalid && categoryForm.get('name').touched">
                                <div class="error-message" *ngIf="categoryForm.get('name').hasError('required') && categoryForm.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                    
                      
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Default</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="defaultCheckbox" formControlName="defaultStatus" data-original-title="" title="">
                                    <label for="defaultCheckbox">Default</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="statusCheckbox" formControlName="status" data-original-title="" title="">
                                    <label for="statusCheckbox">Active</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4"><label>Add Description :</label></div>
                            <div class="col-xl-10 col-sm-7">
                                <div class="ps-0 description-sm">
                                    <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="isNameControlEmpty()">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->