<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Blog</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input class="form-control" id="validationCustom0" type="text" disabled required="">
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0"><small>Auto Generated</small></label>
                            </div>
                            <label class="col-xl-1 col-lg-1 col-md-2 col-sm-4">Status</label>
                            <div class="col-xl-5 col-lg-2 col-md-1 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="status" formControlName="status" data-original-title="" title="">
                                    <label for="status">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label>Post Date</label>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                                <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="start_date" #f="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="f.toggle()" type="button">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="title">Title</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="title" formControlName="title" required="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label>Short Description :</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="ps-0 description-sm">
                                    <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label>Long Description :</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="ps-0 description-sm">
                                    <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12">
                            <div class="add-product">
                                <div class="row">
                                    <div  class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file sizz">
                                                    <input class="upload sizz" type="file" (change)="readUrl($event,i)">
                                                    <img class="img-fluid image_zoom_1 sizz" alt="" [src]="item.img">
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="isNameControlEmpty()">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->