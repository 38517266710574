export interface ListAttributesDB {
    name: string;
    values: string,
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTATRRIBUTEDB: ListAttributesDB[] = [
    {
        name: " Color",
        values: "Grey, Blue, Gree, Red",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " Size",
        values: "Small, Medium, Large",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
