import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from '../catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-attribute',
  templateUrl: './create-attribute.component.html',
  styleUrls: ['./create-attribute.component.scss']
})

export class CreateAttributeComponent implements OnInit {

  public user = localStorage.getItem('user');

  formGroup: FormGroup;
  formData: FormData = new FormData();
  showColorInput: boolean = false;
  detailData: any[] = [];

  constructor(
    private formBuilder: FormBuilder, 
    private catalogService: CatalogService, 
    private alertService: AlertService, 
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    // initialize the form with form controls and validators
    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      name: ['', [Validators.required]],
      apply_color: [false], 
      isactive: [true],
    });

    // Subscribe to changes in apply_color checkbox value
    this.formGroup.get('apply_color').valueChanges.subscribe((value: boolean) => {
      this.showColorInput = value; // Update the flag based on checkbox value
    });

    console.log("this.showColorInput :", this.showColorInput);
  }
  
  // inside MyFormComponent class
  async onSubmit() {
    if (this.formGroup.valid) {
      this.formData.append('id', '0');
      this.formData.append('name', this.formGroup.get('name').value);
      this.formData.append('apply_color', this.formGroup.get('apply_color').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      // this.tableRows.forEach(row => {
      //   const cells = row.nativeElement.cells;
      //   const rowData = {
      //     value: cells[1].querySelector('input').value,
      //     color: cells[2].querySelector('input').value
      //     // Add more fields as needed
      //   };
      //   this.detailData.push(rowData);
      // });

      // console.log("this.tableRows :", this.tableRows);
      console.log('formData:', this.formData);

      // try {
      //   const response = await firstValueFrom(this.catalogService.postCategoryData(this.formData));
      //   console.log('Post successful:', response);

      //   // Set alert properties dynamically
      //   this.alertService.setShowAlert(true);
      //   this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
      //   this.alertService.setAlertText('Record saved successfully');

      //   this.router.navigate(['/catalog/list-category']);

      //   setTimeout(() => {
      //     this.alertService.setShowAlert(false);
      //   }, 1500);
      // } catch (error) {
      //   console.error('Error during post:', error);
      //   // handle the error
      //   this.alertService.setShowAlert(false);
      // }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }
}
