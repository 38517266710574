<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                
                <div class="card-body">
                    <div id="batchDelete" class="category-table custom-datatable transcation-datatable">
                        <!-- <form>
                            <div class="mb-3">
                              <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText"
                                autocomplete="off" />
                            </div>
                          </form> -->
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">Id</th>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">Order No.</th>
                                    <th scope="col" sortable="transaction_id" (sort)="onSort($event)" >Transaction Id</th>
                                    <th scope="col" sortable="date" (sort)="onSort($event)">Date</th>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">Email</th>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">Description</th>
                                    <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">Currency</th>
                                    
                                    <th scope="col" sortable="delivery_status" (sort)="onSort($event)">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of tableItem$ | async ">
                                <th scope="row">{{ item.order_id }}</th>
                                <td>
                                    {{item.order_no}}
                                </td>
                                <td>
                                    {{item.transaction_id}}
                                </td>
                                  <td>{{item.date}}</td>
                                  <td>
                                    {{item.email}}
                                </td>
                                <td>
                                    {{item.description}}
                                </td>
                                <td>{{item.amount}}</td>
                                <td>{{ item.currency }}</td>
                             
                                <td>
                                    <span class='badge {{item.payment_class}}'>{{ item.status }}</span>
                                </td>
                                <!-- <td><span class='badge {{item.order_class}}'>{{ item.delivery_status }}</span></td> -->
                               
                                </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination
                                  [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                </ngb-pagination>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->