import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { LISTPRODUCTDB, ListProductDB } from 'src/app/shared/tables/list-product';
import { CatalogService } from '../catalog.service';
import { Router } from '@angular/router';
import { AlertService } from '../../../alert.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss'],
  providers: [TableService, DecimalPipe]
})

export class ListProductsComponent implements OnInit {

  public user = localStorage.getItem('user');

  public selected = [];
  public listData = [];
  
  public tableItem$: Observable<ListProductDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(
    public service: TableService, 
    private apiService: CatalogService,
    private router: Router,
    private alertService: AlertService
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
    
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(LISTPRODUCTDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  onSelect(itemId: any, event: any) {
    if (event.target.checked) {
      // Add itemId to selected array if checkbox is checked
      if (!this.selected.includes(itemId)) {
        this.selected.push(itemId);
      }
    } else {
      // Remove itemId from selected array if checkbox is unchecked
      const index = this.selected.indexOf(itemId);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    }

    console.log('Selected items:', this.selected);
  }

  editRecord() {
    this.router.navigate(['/catalog/edit-product'], { queryParams: { id: this.selected } });
  }

  deleteRecord() {
    this.apiService.deleteProduct(this.selected).subscribe(
      (data) => {
        console.log('API Delete Response:', data);
        // Handle the data as needed

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record deleted successfully');

        setTimeout(() => {
          this.alertService.setShowAlert(false);
          window.location.reload();
        }, 1500);

      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );
  }

  ngOnInit(): void {
    this.apiService.getProductsData().subscribe(
      (data) => {
        console.log('API Response:', data);
        this.listData = data;
        // Handle the data as needed
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );
  }

}
