export interface ListSpecificationsDB {
    id: string;
    name: string;
    payment_class:string;
    status: string;
   
}

export const LISTSPECIFICATIONDB: ListSpecificationsDB[] = [
    {
        id: "1",
        name: "FEATURES",
        
        "status":"Active",
        "payment_class": 'badge-success',
       
    },
    {
        id: "2",
        name: "PRINTING SPECIFICATIONS",
        
        "status":"Not Active",
        "payment_class": 'badge-danger',
        
    }
]
