export interface CancelOrderDB {
    order_id: string;
    order_date : string;
    customer_name:string;
    email:string;
    contact_no:string;
    payment_method:string;
   total_bill: string;
   status: string;
}

export const CANCELORDERDB: CancelOrderDB[] = [
  
    {
        "order_id": "#1",
        "order_date" : "17-Nov-2023",
        "customer_name": "Basit Ali",
        "email":"basit@gmail.com",
        "contact_no": "+920000000",
        "payment_method":"Bank Transfer",
        "total_bill":"17,000",
        status: "<i class=\"fa fa-circle font-success f-12\"></i>",
    },

    {
        "order_id": "#2",
        "order_date" : "17-Nov-2023",
        "customer_name": "Basit Ali",
        "email":"basit@gmail.com",
        "contact_no": "+920000000",
        "payment_method":"Bank Transfer",
        "total_bill":"17,000",
        status: "<i class=\"fa fa-circle font-success f-12\"></i>",
    },
    {
        "order_id": "#3",
        "order_date" : "17-Nov-2023",
        "customer_name": "Basit Ali",
        "email":"basit@gmail.com",
        "contact_no": "+920000000",
        "payment_method":"Bank Transfer",
        "total_bill":"17,000",
        status: "<i class=\"fa fa-circle font-success f-12\"></i>",
    },
    {
        "order_id": "#4",
        "order_date" : "17-Nov-2023",
        "customer_name": "Basit Ali",
        "email":"basit@gmail.com",
        "contact_no": "+920000000",
        "payment_method":"Bank Transfer",
        "total_bill":"17,000",
        status: "<i class=\"fa fa-circle font-success f-12\"></i>",
    },
]
