import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from '../catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})

export class EditCategoryComponent implements OnInit {

  public user = localStorage.getItem('user');

  public image1 = "assets/images/category/placeholder-image-1.jpg";
  public image2 = "assets/images/category/placeholder-image-2.jpg";
  public image3 = "assets/images/category/placeholder-image-3.jpg";
  public image4 = "assets/images/category/placeholder-image-3.jpg";
  public image5 = "assets/images/category/placeholder-image-2.jpg";

  id: number;
  selectedValue: any = 0;
  formGroup: FormGroup;
  formData: FormData = new FormData();

  constructor(
    private formBuilder: FormBuilder, 
    private catalogService: CatalogService, 
    private alertService: AlertService, 
    private route: ActivatedRoute, 
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    // initialize the form with form controls and validators
    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      name: ['', [Validators.required]],
      title: [''],
      keyword: [''],
      description: [''],
      position: [0],
      redirection_url: [''],
      image_url: [''],
      isactive: [true]
    });

    this.id = +this.route.snapshot.queryParamMap.get('id');

    this.catalogService.getCategoryById(this.id).subscribe(
      (data) => {
        console.log('API Edit Response:', data);

        var position_id = parseInt(data.Position);
        // Handle the data as needed
        this.formGroup.patchValue({
          id: data.Id,
          name: data.Name,
          title: data.MetaTitle,
          keyword: data.MetaKeyword,
          description: data.MetaDescription,
          position: position_id,
          redirection_url: data.NavigationURL,
          image_url: data.ImageURL,
          isactive: (data.IsActive === 1 ? true : false)
        });

        this.selectedValue = position_id;

        if (data.ImageURL !== "" || data.ImageURL !== null) {
          if (position_id === 1) {
            this.image1 = environment.apiUrl + '/uploads/category/' + data.ImageURL;
          }
          else if (position_id === 2) {
            this.image2 = environment.apiUrl + '/uploads/category/' + data.ImageURL;
          }
          else if (position_id === 3) {
            this.image3 = environment.apiUrl + '/uploads/category/' + data.ImageURL;
          }
          else if (position_id === 4) {
            this.image4 = environment.apiUrl + '/uploads/category/' + data.ImageURL;
          }
          else if (position_id === 5) {
            this.image5 = environment.apiUrl + '/uploads/category/' + data.ImageURL;
          }
          else {
            this.image1 = 'assets/images/category/placeholder-image-1.jpg';
            this.image2 = 'assets/images/category/placeholder-image-2.jpg';
            this.image3 = 'assets/images/category/placeholder-image-3.jpg';
            this.image4 = 'assets/images/category/placeholder-image-4.jpg';
            this.image5 = 'assets/images/category/placeholder-image-5.jpg';
          }
        }
        else {
          this.image1 = 'assets/images/category/placeholder-image-1.jpg';
          this.image2 = 'assets/images/category/placeholder-image-2.jpg';
          this.image3 = 'assets/images/category/placeholder-image-3.jpg';
          this.image4 = 'assets/images/category/placeholder-image-4.jpg';
          this.image5 = 'assets/images/category/placeholder-image-5.jpg';
        }
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );
  }

  isNameControlEmpty(): boolean {
    const nameControl = this.formGroup.get('name');
    return nameControl.value === '';
  }

  onSelectionChange(selectedDiv: string): void {
    this.selectedValue = parseInt(selectedDiv);
    console.log('Selected value:', this.selectedValue);
  }
  
  // inside MyFormComponent class
  async onSubmit() {
    console.log('Form submitted:', this.formGroup);
    console.log("Check Valid :", this.formGroup.valid);
    if (this.formGroup.valid) {
      console.log('Form submitted:', this.formGroup);

      this.formData.append('id', this.formGroup.get('id').value);
      this.formData.append('name', this.formGroup.get('name').value);
      this.formData.append('title', this.formGroup.get('title').value);
      this.formData.append('keyword', this.formGroup.get('keyword').value);
      this.formData.append('description', this.formGroup.get('description').value);
      this.formData.append('position', this.formGroup.get('position').value);
      this.formData.append('redirection_url', this.formGroup.get('redirection_url').value);
      this.formData.append('image_url', this.formGroup.get('image_url').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.catalogService.postData(this.formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record saved successfully');

        this.router.navigate(['/catalog/list-category']);

        setTimeout(() => {
          this.alertService.setShowAlert(false);
        }, 3000);
        
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }

  readFileUrl(event: any) {
    const file: File = event.target.files[0]; // Get the selected file
    const reader = new FileReader();
    
    if (event.target.files.length === 0)
      return;

    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      if (this.selectedValue === 1) {
        this.image1 = reader.result.toString();
      } 
      else if (this.selectedValue === 2) {
        this.image2 = reader.result.toString();
      }
      else if (this.selectedValue === 3) {
        this.image3 = reader.result.toString();
      }
      else if (this.selectedValue === 4) {
        this.image4 = reader.result.toString();
      }
      else if (this.selectedValue === 5) {
        this.image5 = reader.result.toString();
      }
    }

    // Add the file to your FormData object
    if (file) {
      //this.formData = new FormData();
      this.formData.append('myFile', file);
    }
  }
}
