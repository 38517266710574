export interface ListBannersDB {
    id:string;
    page_name:string;
    location:string;
    type: string;
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTBANNERSDB: ListBannersDB[] = [
    {
        id: "1",
        page_name:"Home",
        location: "Top Banners",
        type: "Image",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        id: "2",
        page_name:"Home",
        location: "Top Banners",
        type: "Image",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
