export interface OrderDB {
    order_id: string;
    full_name :string;
    email:string;
    contact_no : string;
    country : string;
    signup_date:string;
    last_login:string;
    status: string;
    product: string[];
    payment_class: string;
    payment_status: string;
    paymeny_method: string;
    order_class: string;
    order_status: string;
    date: string;
    total: string;
}

export const ORDERDB: OrderDB[] = [
  
    {
        "order_id": "#51241",
        "full_name": "Muhammad",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/14.jpg'],
        "payment_class": 'badge-success',
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": "badge-primary",
        "order_status": "Shipped",
        "date": "Feb 15,18",
        "total": "$2136"
    },
    {
        "order_id": "#51242",
        "full_name": "Asad",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/14.jpg'],
        "payment_class": 'badge-success',
        "payment_status": 'Awaiting Authentication',
        "paymeny_method": "Debit Card",
        "order_class": "badge-warning",
        "order_status": "Processing",
        "date": "Mar 27,18",
        "total": "$8791"
    },
 
    {
        "order_id": "#51244",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ["assets/images/jewellery/pro/18.jpg"],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Paypal",
        "order_class": "badge-primary",
        "order_status": "Shipped",
        "date": "Sep 1,18",
        "total": "$139"
    },
    {
        "order_id": "#51245",
        "full_name": "Basit Ali",    
            "email" : "basit@gmail.com",
            "contact_no" : "+9200000000",
            "country" : "Pakistan",
            "signup_date" : "07-Dec-2022 11:35",
            "last_login": "05-Jun-2023 06:01",
            "status":"Active",
        "product": ['assets/images/electronics/product/19.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Visa",
        "order_class": "badge-success",
        "order_status": "Delivered",
        "date": "Jan 14,18",
        "total": "$6791"
    },
   
  
    {
        "order_id": "#51248",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/18.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Paypal",
        "order_class": 'badge-primary',
        "order_status": "Shipped",
        "date": "Feb 29,18",
        "total": "$6791"
    },
    {
        "order_id": "#51249",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/17.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": "badge-secondary",
        "order_status": "Processing",
        "date": "Sep 2,18",
        "total": "$9765"
    },
    {
        "order_id": "#51250",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/7.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Credit Card",
        "order_class": "badge-primary",
        "order_status": "Shipped",
        "date": "Dec 10,18",
        "total": "$9705"
    },
  
    {
        "order_id": "#51252",
        "full_name": "Basit Ali",   
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/3.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Payment",
        "paymeny_method": "Credit Card",
        "order_class": "badge-primary",
        "order_status": "Cancelled",
        "date": "Mar 27,18",
        "total": "$18.97"
    },
    {
        "order_id": "#51253",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/11.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": 'badge-success',
        "order_status": "Delivered",
        "date": "Dec 17,18",
        "total": "$19.47"
    },
    {
        "order_id": "#51253",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/11.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": 'badge-success',
        "order_status": "Delivered",
        "date": "Dec 17,18",
        "total": "$19.47"
    },
    {
        "order_id": "#51253",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/11.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": 'badge-success',
        "order_status": "Delivered",
        "date": "Dec 17,18",
        "total": "$19.47"
    },
    {
        "order_id": "#51253",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/11.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": 'badge-success',
        "order_status": "Delivered",
        "date": "Dec 17,18",
        "total": "$19.47"
    },
    {
        "order_id": "#51254",
        "full_name": "Basit Ali",
        "email" : "basit@gmail.com",
        "contact_no" : "+9200000000",
        "country" : "Pakistan",
        "signup_date" : "07-Dec-2022 11:35",
        "last_login": "05-Jun-2023 06:01",
        "status":"Active",
        "product": ['assets/images/electronics/product/11.jpg'],
        "payment_class": "badge-success",
        "payment_status": "Paid",
        "paymeny_method": "Master Card",
        "order_class": "badge-primary",
        "order_status": "Shipped",
        "date": "Nov 29,18",
        "total": "$7.48"
    },
]
