<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Product</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input type="text" class="form-control" id="id" formControlName="id" required>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id"><small>Auto Generated</small></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="code">Code</label>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-8">
                                <input type="text" class="form-control" id="code" formControlName="code">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Name <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="name" formControlName="name" [class.invalid]="formGroup.get('name').invalid && formGroup.get('name').touched">
                                <div class="error-message" *ngIf="formGroup.get('name').hasError('required') && formGroup.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="category" class="mb-0">Category</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <select class="form-control form-select" id="category" formControlName="category" [class.invalid]="formGroup.get('category').invalid && formGroup.get('category').touched" aria-label="Default select example">
                                    <option value="0">Select</option>
                                    <option *ngFor="let item of categoryList" [value]="item.Id">{{ item.Name }}</option>
                                </select>
                                <div class="error-message" *ngIf="formGroup.get('category').hasError('required') && formGroup.get('category').touched">
                                    Category is required.
                                </div>
                                <div class="error-message" *ngIf="formGroup.get('category').hasError('invalidSelect') && formGroup.get('category').touched">
                                    Please select Category.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="price">Price <span>*</span></label>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-8">
                                <input type="text" class="form-control" id="price" formControlName="price" [class.invalid]="formGroup.get('price').invalid && formGroup.get('price').touched">
                                <div class="error-message" *ngIf="formGroup.get('price').hasError('required') && formGroup.get('price').touched">
                                    Price is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="price">Discount %</label>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-8">
                                <input type="text" class="form-control" id="discount" formControlName="discount">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="price">Color</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <ng-multiselect-dropdown
                                    [settings]="colorSettings"
                                    [data]="colorList"
                                    formControlName="colors"
                                    (onSelect)="onColorSelect($event)"
                                    (onDeSelect)="onColorDeSelect($event)"
                                >
                                </ng-multiselect-dropdown>
                                <!-- <input type="text" class="form-control" id="color" formControlName="color"> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="price">Size</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <ng-multiselect-dropdown
                                    [settings]="sizeSettings"
                                    [data]="sizeList"
                                    formControlName="sizes"
                                    (onSelect)="onSizeSelect($event)"
                                    (onDeSelect)="onSizeDeSelect($event)"
                                >
                                </ng-multiselect-dropdown>
                                <!-- <input type="text" class="form-control" id="size" formControlName="size"> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="price">Description</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="ps-0 description-sm ">
                                    <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Featured</label>
                            <div class="col-xl-4 col-lg-3 col-md-3 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="featured" formControlName="featured" data-original-title="" title="">
                                    <label for="featured">Yes/No</label>
                                </div>
                            </div>
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Top Rated</label>
                            <div class="col-xl-4 col-lg-3 col-md-3 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="top_rated" formControlName="top_rated" data-original-title="" title="">
                                    <label for="top_rated">Yes/No</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Available Stock</label>
                            <div class="col-xl-4 col-lg-3 col-md-3 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="stock" formControlName="stock" data-original-title="" title="">
                                    <label for="stock">In Stock/Out of Stock</label>
                                </div>
                            </div>
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-4 col-lg-3 col-md-3 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="isactive" formControlName="isactive" data-original-title="" title="">
                                    <label for="isactive">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="dropzone-custom">
                                <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->