export interface ListDeliveryChargesDB {
    id:string;
    country : string;
    state: string;
    city:string;
    delivery_charges:string;
    delivery_days:string;
   payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTDELIVERYCHARGESDB: ListDeliveryChargesDB[] = [
    {
        id:"1",
        country:"Pakistan",
        state:"Sindh",
        city:"Karachi",
        delivery_charges:"0",
        delivery_days:"1-2 Days",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        id:"2",
        country:"Pakistan",
        state:"Sindh",
        city:"Karachi",
        delivery_charges:"0",
        delivery_days:"1-2 Days",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
