export interface ListBlogsDB {
    date: string,
    title: string;
    short_description: string;
    post_by: string;
    status: string;
    payment_class:string;
    checked?: boolean;
}

export const LISTBLOGDB: ListBlogsDB[] = [
    {
        date: "25 Jan 2018",
        title: "you how all this mistaken",
        short_description: "Consequences that are extremely painful.",
        post_by: "Admin",
        status: "Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        date: "25 Jan 2018",
        title: "you how all this mistaken",
        short_description: "Consequences that are extremely painful.",
        post_by: "Admin",
        status: "Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        date: "25 Jan 2018",
        title: "you how all this mistaken",
        short_description: "Consequences that are extremely painful.",
        post_by: "Admin",
        status: "Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        date: "25 Jan 2018",
        title: "you how all this mistaken",
        short_description: "Consequences that are extremely painful.",
        post_by: "Admin",
        status: "Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
