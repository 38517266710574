export interface ListSlidersDB {
    id:string;
    title:string;
    page_name:string;
    sequence: string;
    image:string[];
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTSLIDERSDB: ListSlidersDB[] = [
    {
        id: "1",
        title:"Honeywell",
        page_name: " Home",
        sequence: "2",
        image: ['assets/images/electronics/product/12.jpg'],
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        id: "2",
        title:"Honeywell2",
        page_name: " Home",
        sequence: "2",
        image: ['assets/images/electronics/product/12.jpg'],
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
