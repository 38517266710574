import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingService } from '../../setting/setting.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-banner',
  templateUrl: './create-banner.component.html',
  styleUrls: ['./create-banner.component.scss']
})

export class CreateBannersComponent implements OnInit {

  public user = localStorage.getItem('user');
  
  formGroup: FormGroup;
  public banner_image = "assets/images/banner/placeholder.jpg";
  public category_banner_image = "assets/images/banner/category_placeholder.jpg";
  selectedValue: any = 0;
  formData: FormData = new FormData();

  constructor(
    private formBuilder: FormBuilder, 
    private apiService: SettingService,
    private alertService: AlertService,
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      page_name: [0, Validators.min(1)],
      location: [0, Validators.min(1)],
      file_type: [0, Validators.min(1)],
      redirection_url: ['', [Validators.required]],
      isactive: [true]
    });
  }

  onSelectionChange(selectedDiv: string): void {
    this.selectedValue = parseInt(selectedDiv);
    console.log('Selected value:', this.selectedValue);
  }
  
  async onSubmit() {
    console.log('Form submitted:', this.formGroup);
    console.log("Check Valid :", this.formGroup.valid);
    if (this.formGroup.valid) {
      
      this.formData.append('id', '0');
      this.formData.append('title', this.formGroup.get('title').value);
      this.formData.append('description', this.formGroup.get('description').value);
      this.formData.append('page_name', this.formGroup.get('page_name').value);
      this.formData.append('location', this.formGroup.get('location').value);
      this.formData.append('file_type', this.formGroup.get('file_type').value);
      this.formData.append('redirection_url', this.formGroup.get('redirection_url').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.apiService.postBannerData(this.formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record saved successfully');

        this.router.navigate(['/settings/list-banners']);

        setTimeout(() => {
          this.alertService.setShowAlert(false);
        }, 1500);
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }

  readFileUrl(event: any) {
    const file: File = event.target.files[0]; // Get the selected file
    const reader = new FileReader();
    
    if (event.target.files.length === 0)
      return;

    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      if (this.selectedValue === 1) {
        this.banner_image = reader.result.toString();
      } 
      else if (this.selectedValue === 2) {
        this.category_banner_image = reader.result.toString();
      }
    }

    // Add the file to your FormData object
    if (file) {
      this.formData = new FormData();
      this.formData.append('myFile', file);
    }
  }
}
