import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private showAlertSource = new BehaviorSubject<boolean>(false);
  showAlert$ = this.showAlertSource.asObservable();

  private alertTypeSource = new BehaviorSubject<string>('success');
  alertType$ = this.alertTypeSource.asObservable();

  private alertTextSource = new BehaviorSubject<string>('Default alert text.');
  alertText$ = this.alertTextSource.asObservable();

  setShowAlert(value: boolean) {
    this.showAlertSource.next(value);
  }

  setAlertType(type: string) {
    this.alertTypeSource.next(type);
  }

  setAlertText(text: string) {
    this.alertTextSource.next(text);
  }
}