<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>List Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="custom-datatable">
                        <button class="btn btn-danger me-1 mb-3" style="float: right;">Delete</button>
                        <button class="btn btn-secondary me-1 mb-3" style="float: right;">Edit</button>
                        <button class="btn btn-primary me-1 mb-3" [routerLink]="'/catalog/create-specification'" style="float: right;">Add</button>
                        <div class="clearfix"></div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="title" (sort)="onSort($event)">ID</th>
                                    <th scope="col" sortable="code" (sort)="onSort($event)">FullName</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tableItem$ | async">
                                    <th scope="row">
                                        {{ item.id }}
                                    </th>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        <span class='badge {{item.payment_class}}'>{{ item.status }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination
                              [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                            </ngb-pagination>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->