<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3 col-md-3 xl-25">
            <div class="card o-hidden widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'navigation'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Earnings</span>
                            <h3 class="mb-0">$ 
                                <span class="counter">6659</span>
                            </h3>
                            <span>This Month</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-3 xl-25">
            <div class="card o-hidden  widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Products</span>
                            <h3 class="mb-0">
                                <span class="counter">9856</span>
                            </h3>
                            <span>This Month</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-3 xl-25">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'message-square'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Messages</span>
                            <h3 class="mb-0"> 
                                <span class="counter">893</span>
                            </h3>
                            <span>This Month</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-3 xl-25">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8">
                            <span class="m-0">Customers</span>
                            <h3 class="mb-0"> 
                                <span class="counter">50</span>
                            </h3>
                            <span>This Month</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Latest Orders</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Order Id</th>
                                    <th scope="col">Order Date</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Contact No</th>
                                    <th scope="col">Payment Mode</th>
                                    <th scope="col">Bill Ammount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of (tableItem$ | async)">
                                    <th scope="row">{{ item.order_id }}</th>
                                    <td><span>{{ item.order_date }}</span></td>
                                    <td><span>{{ item.customer_name }}</span></td>
                                    <td><span>{{ item.email }}</span></td>
                                    <td><span>{{ item.contact_no }}</span></td>
                                    <td><span>{{ item.payment_method }}</span></td>
                                    <td><span>{{ item.total_bill }}</span></td>
                                  </tr>
                            </tbody>
                        </table>
                        <a href="/sales/view-orders" class="btn btn-primary">View All Orders</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Latest Payments</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Order No.</th>
                                    <th scope="col">Transaction Id</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Currency</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">142</th>
                                    <td>36</td>
                                    <td>#212145214510</td>
                                    <td>Sep 2,19</td>
                                    <td>basit&#64;gmail.com</td>
                                    <td>Just A Try Just A Try</td>
                                    <td>$175</td>
                                    <td>PKR</td>
                                    <td>
                                        <span class='badge badge-success'>Received</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">142</th>
                                    <td>36</td>
                                    <td>#212145214510</td>
                                    <td>Sep 2,19</td>
                                    <td>basit&#64;gmail.com</td>
                                    <td>Just A Try Just A Try</td>
                                    <td>$175</td>
                                    <td>PKR</td>
                                    <td>
                                        <span class='badge badge-danger'>Pending</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">142</th>
                                    <td>36</td>
                                    <td>#212145214510</td>
                                    <td>Sep 2,19</td>
                                    <td>basit&#64;gmail.com</td>
                                    <td>Just A Try Just A Try</td>
                                    <td>$175</td>
                                    <td>PKR</td>
                                    <td>
                                        <span class='badge badge-success'>Received</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">142</th>
                                    <td>36</td>
                                    <td>#212145214510</td>
                                    <td>Sep 2,19</td>
                                    <td>basit&#64;gmail.com</td>
                                    <td>Just A Try Just A Try</td>
                                    <td>$175</td>
                                    <td>PKR</td>
                                    <td>
                                        <span class='badge badge-danger'>Pending</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="/sales/transactions" class="btn btn-primary">View All Payments</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->