export interface ListProductDB {
    id:string;
    brand:string;
    code:string;
    name: string;
    price: string,
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTPRODUCTDB: ListProductDB[] = [
    {
        id: "1",
        brand: "Honeywell",
        code: "001",

        name: " Shirt",
        price: "1352",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        id: "2",
        brand: "Honeywell2",
        code: "002",
        name: " Size",
        price: "1950",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    }
]
