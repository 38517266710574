import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ListStateComponent } from './list-state/list-state.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCountryComponent } from './create-country/create-country.component';
import { SettingRoutingModule } from './setting-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../../shared/shared.module';
import { ListCountriesComponent } from './list-countries/list-countries.component';
import { CreateStateComponent } from './create-state/create-state.component';
import { ListCityComponent } from './list-city/list-city.component';
import { CreateCityComponent } from './create-city/create-city.component';
import { BannersComponent } from './banners/banners.component';
import { CreateBannersComponent } from './create-banners/create-banner.component';
import { EditBannersComponent } from './edit-banners/edit-banner.component';
import { ListSlidersComponent } from './list-sliders/list-sliders.component';
import { CreateSlidersComponent } from './create-sliders/create-sliders.component';
import { ListDeliveryChargesComponent } from './list-delivery_charges/list-delivery_charges.component';
import { CreateDeliveryChargesComponent } from './create-delivery_charges/create-delivery_charges.component';
import { ListPaymentMethodsComponent } from './list-payment_methods/list-payment_methods.component';
import { CreatePaymentMethodsComponent } from './create-payment_methods/create-payment_methods.component';
import { ListSMSHISTORYComponent } from './list-sms_history/list-sms_history.component';

@NgModule({
  declarations: [
    ProfileComponent, 
    ListCountriesComponent, CreateCountryComponent, 
    ListStateComponent, CreateStateComponent, 
    ListCityComponent, CreateCityComponent, 
    BannersComponent, CreateBannersComponent, EditBannersComponent, 
    ListSlidersComponent, CreateSlidersComponent, 
    ListDeliveryChargesComponent, CreateDeliveryChargesComponent, 
    ListPaymentMethodsComponent, CreatePaymentMethodsComponent, 
    ListSMSHISTORYComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    FormsModule,
    CKEditorModule
  ]
})
export class SettingModule { }
