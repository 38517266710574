import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from "../../../shared/service/account.service";
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user = localStorage.getItem('user');

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;

  formData: FormData = new FormData();

  constructor(
    private fb: UntypedFormBuilder, 
    public apiService: AccountService, 
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
    else {
      this.router.navigate(['/dashboard/default']);
    }

    this.loginForm = this.fb.group({
      userid: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      password: ['', [
        Validators.required,
        Validators.minLength(8)
      ]]
    })
    // this.createLoginForm();
    // this.createRegisterForm();
  }

  owlcarousel = [
    {
      title: "Welcome to Shanti",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Shanti",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Shanti",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]

  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  // createLoginForm() {
  //   this.loginForm = this.formBuilder.group({
  //     userName: [''],
  //     password: [''],
  //   })
  // }

  // createRegisterForm() {
  //   this.registerForm = this.formBuilder.group({
  //     userName: [''],
  //     password: [''],
  //     confirmPassword: [''],
  //   })
  // }

  ngOnInit() {
  }

  async onSubmit() {
    if (this.loginForm.valid) {
      this.formData = new FormData();
      this.formData.append('userid', this.loginForm.get('userid').value);
      this.formData.append('password', this.loginForm.get('password').value);

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.apiService.loginAdminAccount(this.formData));
        console.log('Post successful:', response);

        if (response.error === "Invalid user id or password") {
          alert(response.error);
          return;
        }

        localStorage.setItem("user", JSON.stringify(response.data));
        this.router.navigate(['/dashboard/default']);
      } catch (error) {
        console.error('Error during post:', error);
      }
    } else {
      //Object.values(this.registerForm.controls).forEach(control => control.markAsTouched());
    }
  }

}
