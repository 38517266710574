<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input type="text" class="form-control" id="id" formControlName="id" required>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id"><small>Auto Generated</small></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Name <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="name" formControlName="name" [class.invalid]="formGroup.get('name').invalid && formGroup.get('name').touched">
                                <div class="error-message" *ngIf="formGroup.get('name').hasError('required') && formGroup.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Apply Color</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="apply_color" formControlName="apply_color" data-original-title="" title="">
                                    <label for="apply_color">Yes/No</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="isactive" formControlName="isactive" data-original-title="" title="">
                                    <label for="isactive">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <a href="javascript:void(0)" style="display: flex;justify-content: center;">
                                                    <img style="width: 18px;" src="../../../../assets/images/add.svg" alt="">
                                                </a>
                                            </th>
                                            <th scope="col">Value</th>
                                            <th *ngIf="showColorInput" scope="col">Color</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="width: 70px;">
                                                <a style="display: flex; justify-content: center;">
                                                    <img style="width: 18px;" src="../../../../assets/images/delete.svg" alt="">
                                                </a>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control">
                                            </td>
                                            <td *ngIf="showColorInput">
                                                <input type="text" class="form-control">
                                            </td>
                                            <td *ngIf="showColorInput" style="width: 50px;">
                                                <input type="color">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->