import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SettingService {
  //private apiUrl = 'http://localhost:3000'; // replace with your API endpoint
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  // Start Banner
  getBannersData(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/allBanners`);
  }

  postBannerData(formData: FormData): Observable<any> {
    console.log("Service Data :", formData);

    const headers = new HttpHeaders();

    return this.http.post(`${this.apiUrl}/saveBanner`, formData, { headers });
  }

  getBannerById(id): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/banners/${id}`);
  }

  deleteBanner(ids: number[]): Observable<any> {
    console.log("delete service ids :", ids);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ids: ids } // Pass an object with 'ids' key containing the array of category IDs
    };
    return this.http.delete<any>(`${this.apiUrl}/deleteBanner`, options);
  }
  // End Banner
}
