import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingService } from '../../setting/setting.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.scss']
})

export class EditBannersComponent implements OnInit {

  public user = localStorage.getItem('user');
  
  formGroup: FormGroup;
  public banner_image = "assets/images/banner/placeholder.jpg";
  public category_banner_image = "assets/images/banner/category_placeholder.jpg";
  selectedValue: any = 0;
  formData: FormData = new FormData();
  id: number;

  constructor(
    private formBuilder: FormBuilder, 
    private apiService: SettingService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      page_name: [0, Validators.min(1)],
      location: [0, Validators.min(1)],
      file_type: [0, Validators.min(1)],
      redirection_url: ['', [Validators.required]],
      image_url: [''],
      isactive: [true]
    });

    this.id = +this.route.snapshot.queryParamMap.get('id');

    this.apiService.getBannerById(this.id).subscribe(
      (data) => {
        console.log('API Edit Response:', data);

        var page_id = parseInt(data.PageId);
        // Handle the data as needed
        this.formGroup.patchValue({
          id: data.Id,
          title: data.Title,
          description: data.Description,
          page_name: page_id,
          location: data.PlacementId,
          file_type: data.Type,
          redirection_url: data.NavigationURL,
          image_url: data.ImageURL,
          isactive: (data.IsActive === 1 ? true : false)
        });

        this.selectedValue = page_id;

        if (data.ImageURL !== "" || data.ImageURL !== null) {
          if (page_id === 1) {
            this.banner_image = environment.apiUrl + '/uploads/home/' + data.ImageURL;
          }
          else if (page_id === 2) {
            this.category_banner_image = environment.apiUrl + '/uploads/home/' + data.ImageURL;
          }
          else {
            this.banner_image = 'assets/images/banner/placeholder.jpg';
            this.category_banner_image = "assets/images/banner/category_placeholder.jpg";
          }
        }
        else {
          this.banner_image = 'assets/images/banner/placeholder.jpg';
          this.category_banner_image = "assets/images/banner/category_placeholder.jpg";
        }
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );
  }

  onSelectionChange(selectedDiv: string): void {
    this.selectedValue = parseInt(selectedDiv);
    console.log('Selected value:', this.selectedValue);
  }
  
  // inside MyFormComponent class
  async onSubmit() {
    console.log('Form submitted:', this.formGroup);
    console.log("Check Valid :", this.formGroup.valid);
    if (this.formGroup.valid) {
      
      this.formData.append('id', this.formGroup.get('id').value);
      this.formData.append('title', this.formGroup.get('title').value);
      this.formData.append('description', this.formGroup.get('description').value);
      this.formData.append('page_name', this.formGroup.get('page_name').value);
      this.formData.append('location', this.formGroup.get('location').value);
      this.formData.append('file_type', this.formGroup.get('file_type').value);
      this.formData.append('redirection_url', this.formGroup.get('redirection_url').value);
      this.formData.append('image_url', this.formGroup.get('image_url').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.apiService.postBannerData(this.formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record saved successfully');

        this.router.navigate(['/settings/list-banners']);

        setTimeout(() => {
          this.alertService.setShowAlert(false);
        }, 3000);
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }

  readFileUrl(event: any) {
    const file: File = event.target.files[0]; // Get the selected file
    const reader = new FileReader();
    
    if (event.target.files.length === 0)
      return;

    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      if (this.selectedValue === 1) {
        this.banner_image = reader.result.toString();
      } 
      else if (this.selectedValue === 2) {
        this.category_banner_image = reader.result.toString();
      }
    }

    // Add the file to your FormData object
    if (file) {
      this.formData = new FormData();
      this.formData.append('myFile', file);
    }
  }
}
