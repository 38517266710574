export interface TransactionsDB {
    order_id: string;
    order_no : string;
    email : string;
    description  : string;
    currency : string;
    transaction_id: string;
    date: string;
    pay_method: string;
    payment_class:string;
    status: string;
    amount: string;
}

export const TRANSACTIONDB: TransactionsDB[] = [
    {
        order_id: "142",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#212145214510",
        date: "Sep 2,19",
        pay_method: "Paypal",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$175/-"
    },
    {
        order_id: "217",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#784561421721",
        date: "Dec 10,19",
        pay_method: "Stripe",
        "status":"Pending",
        "payment_class": 'badge-warning',
        amount: "$845/-"
    },
    {
        order_id: "546",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#476547821142",
        date: "Feb 15,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$314/-"
    },
    {
        order_id: "617",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#745384127541",
        date: "Mar 27,19",
        pay_method: "Paypal",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$217/-"
    },
    {
        order_id: "565",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#96725125102",
        date: "Sep 1,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$542/-"
    },
    {
        order_id: "754",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#547121023651",
        date: "May 18,19",
        pay_method: "Paypal",
        "status":"Pending",
        "payment_class": 'badge-warning',
        amount: "$2141/-"
    },
    {
        order_id: "164",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#876412242215",
        date: "Jan 14,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$4872/-"
    },
    {
        order_id: "841",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#31534221621",
        date: "Apr 22,19",
        pay_method: "Paypal",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$7841/-"
    },
    {
        order_id: "354", 
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#78412457421",
        date: "Mar 26,19",
        pay_method: "Paypal",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$2784/-"
    },
    {
        order_id: "784",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#241524757448",
        date: "Feb 29,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$461/-"
    },
    {
        order_id: "142",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#212145214510",
        date: "Sep 2,19",
        pay_method: "Paypal",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$147/-"
    },
    {
        order_id: "217",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#784561421721",
        date: "Dec 10,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$845/-"
    },
    {
        order_id: "547",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#476547821142",
        date: "Feb 15,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$317/-"
    },
    {
        order_id: "671",
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#745384127541",
        date: "Mar 27,19",
        pay_method: "Paypal",
        "status":"Pending",
        "payment_class": 'badge-warning',
        amount: "$217/-"
    },
    {
        order_id: "565", 
        order_no: "#36",
        email: "basit@gmail.com",
        description : "Just A Try Just A Try ",
        currency: "PKR",

        transaction_id: "#96725125102",
        date: "Sep 1,19",
        pay_method: "Stripe",
        "status":"Received",
        "payment_class": 'badge-success',
        amount: "$542/-"
    },
]