export interface ListBrandsDB {
    name: string;
    description: string;
    payment_class:string;
    status: string;
    image: string;
    checked?: boolean;
}

export const LISTBRANDDB: ListBrandsDB[] = [
    {
        name: " Honeywell",
        description: " Honeywell",
       
        image: 'assets/images/dashboard/user5.jpg',
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " Intel",
        description: " Intel",
       
        image: 'assets/images/dashboard/user5.jpg',
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " HP",
        description: " HP",
      
        image: 'assets/images/dashboard/user5.jpg',
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " Lenovo",
        description: " Lenovo",
       
        image: 'assets/images/dashboard/user5.jpg',
        "status":"Not Active",
        "payment_class": 'badge-danger',
        checked: false
    }
]
