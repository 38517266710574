export interface ListPaymentMethodDB {
    id:string;
    name: string;
    default: string,
    payment_class:string;
    status: string;
    default_class:string;
   
    checked?: boolean;
}

export const LISTPAYMENTMETHODDB: ListPaymentMethodDB[] = [
    {
        id:"1",
        name:"Yasir",
        "status":"Active",
        "payment_class": 'badge-success',
        "default":"No",
        "default_class": 'badge-danger',
        checked: false
    },
    {
        id:"2",
        name:"Ahmed",
        "status":"Active",
        "payment_class": 'badge-success',
        "default":"Yes",
        "default_class": 'badge-success',
        checked: false

    }
]
