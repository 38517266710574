<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>List Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="custom-datatable">

                        <!-- <button class="btn btn-danger me-1 mb-3" style="float: right;">Delete</button>
                        <button class="btn btn-secondary me-1 mb-3" style="float: right;">Edit</button>
                        <button class="btn btn-primary me-1 mb-3" [routerLink]="'/settings/create-countries'"
                            style="float: right;">Add</button> -->
                        <div class="clearfix"></div>
                        <!-- <div class="form-group row">
                            <div class="col-xl-6 col-lg-3 col-md-3 col-sm-4 d-flex">
                              
                                    <label for="validationCustom0">Search :</label>
                                    <input class="form-control ml-5" style="margin-left: 15px;" id="validationCustom0" type="text">
                            </div>


                        </div> -->
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="title" (sort)="onSort($event)">To</th>
                                    <th scope="col" sortable="code" (sort)="onSort($event)">Message</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Sent</th>
                                    <th scope="col" sortable="discount" (sort)="onSort($event)">Sent On</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Type</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Response</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Transaction Id</th>                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tableItem$ | async">
                                    <th scope="row">
                                        {{ item.to }}
                                    </th>
                                    <td>
                                        {{item.message}}
                                    </td>
                                    <td>
                                        <span class='badge {{item.sent_class}}'>{{ item.sent }}</span>
                                    </td>
                                   
                                    <td>
                                        {{item.sent_on}}
                                    </td>
                                    <td>
                                        <span class='badge {{item.type_class}}'>{{ item.type }}</span>
                                    </td>
                                    <td>
                                        {{item.response}}
                                    </td>
                                    <td>
                                        {{item.transaction_id}}
                                    </td>
                                  
                                  
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->