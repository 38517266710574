import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

// Import Page Component
import { CatalogRoutingModule } from './catalog-routing.module';
import { ListCategoryComponent } from './list-category/list-category.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { ListBrandComponent } from './list-brand/list-brand.component';
import { CreateBrandComponent } from './create-brand/create-brand.component';
import { ListAttributeComponent } from './list-attribute/list-attribute.component';
import { CreateAttributeComponent } from './create-attribute/create-attribute.component';
import { ListSpecificationComponent } from './list-specification/list-specification.component';
import { CreatespecificationComponent } from './create-specification/create-specification.component';
import { ListProductsComponent } from './list-products/list-products.component';
import { CreateProductComponent } from './create-product/create-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CreateMostSellingComponent } from './create-most-selling/create-most-selling.component';
import { ListMostSellingComponent } from './list-most-selling/list-most-selling.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
  declarations: [
    ListCategoryComponent, CreateCategoryComponent, EditCategoryComponent, 
    ListBrandComponent, CreateBrandComponent,
    ListAttributeComponent, CreateAttributeComponent,
    ListSpecificationComponent, CreatespecificationComponent,
    ListProductsComponent, CreateProductComponent, EditProductComponent, 
    CreateMostSellingComponent, ListMostSellingComponent
  ],
  imports: [
    CommonModule,
    CatalogRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    CKEditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDropzoneModule,
  ]
})
export class CatalogModule { }
