export interface ListSmsDB {
   to:string;
    message:string;
   
    sent_on:string;
   
    response:string;
    transaction_id:string;
    checked?: boolean;
    sent: string,
    sent_class:string;
    type: string;
    type_class:string;
}

export const LISTSMSDB: ListSmsDB[] = [
    {
        to:"12345678",
        message:"Lorem Lorem Lorem",
        "sent":"Yes",
        "sent_class": 'badge-success',
        "type":"Send",
        "type_class": 'badge-success',
        sent_on: " 07-Feb-2024",
        response:"Message Sent",
        transaction_id:"556688",
        checked: false
    },
    {
        to:"12345678",
        message:"Lorem Lorem Lorem",
        sent_on: " 07-Feb-2024",
        response:"Message Sent",
        transaction_id:"556688",
        "sent":"Yes",
        "sent_class": 'badge-success',
        "type":"Send",
        "type_class": 'badge-success',
        checked: false
    }
]
