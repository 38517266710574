import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { LISTSPECIFICATIONDB, ListSpecificationsDB } from 'src/app/shared/tables/list-specification';
import { CatalogService } from '../catalog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-specification',
  templateUrl: './list-specification.component.html',
  styleUrls: ['./list-specification.component.scss'],
  providers: [TableService, DecimalPipe]
})

export class ListSpecificationComponent implements OnInit {

  public user = localStorage.getItem('user');

  public selected = [];
  
  public tableItem$: Observable<ListSpecificationsDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(
    public service: TableService, 
    private catalogService: CatalogService, 
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }

    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(LISTSPECIFICATIONDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit(): void {
    this.catalogService.getCategoriesData().subscribe(
      (data) => {
        console.log('API Response:', data);
        // Handle the data as needed
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );
  }

}
