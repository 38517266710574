<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <!-- <div class="card-header">
          <h5>Manage Order</h5>
        </div> -->
        <div class="card-body">
          <div class="custom-datatable">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" sortable="order_id" (sort)="onSort($event)">Id</th>
                  <th scope="col" sortable="product" (sort)="onSort($event)">Full Name</th>
                  <th scope="col" sortable="payment_status" (sort)="onSort($event)">Email</th>
                  <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Contact No.</th>
                  <th scope="col" sortable="order_status" (sort)="onSort($event)">Country</th>
                  <th scope="col" sortable="date" (sort)="onSort($event)">Image</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Signup Date</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Last Login</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="this.listData.length > 0; else noData">
                  <tr *ngFor="let item of this.listData">
                    <td><span>{{ item.FullName }}</span></td>
                    <td><span>{{ item.Email }}</span></td>
                    <td><span>{{ item.ContactNo }}</span></td>
                    <td><span>{{ item.CountryName }}</span></td>
                    <td><span>{{ item.ProfilePicture }}</span></td>
                    <td><span>{{ item.LastModifiedOn }}</span></td>
                    <td><span>{{ item.LastLogin }}</span></td>
                    <td>
                      <span class='badge {{ item.IsActive === 1 ? "badge-success" : "badge-danger" }}'>{{ item.IsActive === 1 ? 'Active' : 'Inactive' }}</span>
                    </td>
                  </tr>
              </ng-container>
              <ng-template #noData>
                  <tr class="text-center">
                      <td colspan="9">No data available</td>
                  </tr>
              </ng-template>
              </tbody>
            </table>
            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                [pageSize]="service.pageSize">
              </ngb-pagination>
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [value]="10" [selected] ="true" [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
