import { Component, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ViewChild, ElementRef } from '@angular/core';
import { CatalogService } from '../catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-add-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})

export class CreateProductComponent implements OnInit {

  public user = localStorage.getItem('user');

  formGroup: FormGroup;
  formData: FormData = new FormData();

  public Editor = ClassicEditor;
  public categoryList = [];

  colorList = [];
  colorSettings: IDropdownSettings = {};
  selectedColors = [];

  sizeList = [];
  sizeSettings: IDropdownSettings = {};
  selectedSizes = [];

  constructor(
    private formBuilder: FormBuilder, 
    private apiService: CatalogService,
    private alertService: AlertService,
    private router: Router
  ) {
    if (!this.user) {
      this.router.navigate(['/login']);
    }
  }

  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    // initialize the form with form controls and validators
    this.formGroup = this.formBuilder.group({
      id: { value: '', disabled: true },
      code: [''],
      name: ['', [Validators.required]],
      category: [0, Validators.min(1)],
      price: [0, [Validators.required]],
      discount: [0],
      colors: [this.selectedColors],
      sizes: [this.selectedSizes],
      description: [''],
      featured: [false],
      top_rated: [false],
      stock: [true],
      isactive: [true]
    });

    this.apiService.getActiveCategoriesData().subscribe(
      (data) => {
        console.log('data:', data);
        this.categoryList = data;
      },
      (error) => {
        console.error('API Category Error:', error);
        // Handle the error
      }
    );

    //Color
    this.colorList = [
      { item_id: 1, item_text: 'Black' },
      { item_id: 2, item_text: 'Blue' },
      { item_id: 3, item_text: 'Brown' },
      { item_id: 4, item_text: 'Gray' },
      { item_id: 5, item_text: 'Green' },
      { item_id: 6, item_text: 'Orange' },
      { item_id: 7, item_text: 'Pink' },
      { item_id: 8, item_text: 'Purple' },
      { item_id: 9, item_text: 'Red' },
      { item_id: 10, item_text: 'White' },
      { item_id: 11, item_text: 'Yellow' },
    ];

    this.colorSettings = {
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      allowSearchFilter: true
    };

    //Size
    this.sizeList = [
      { item_id: 1, item_text: 'Small' },
      { item_id: 2, item_text: 'Medium' },
      { item_id: 3, item_text: 'Large' }
    ];

    this.sizeSettings = {
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false
    };
  }

  async onSubmit() {
    if (this.formGroup.valid) {
      this.formData.append('id', '0');
      this.formData.append('code', this.formGroup.get('code').value);
      this.formData.append('name', this.formGroup.get('name').value);
      this.formData.append('category', this.formGroup.get('category').value);
      this.formData.append('brand', '1');
      this.formData.append('price', this.formGroup.get('price').value);
      this.formData.append('discount', this.formGroup.get('discount').value);
      this.formData.append('colors', this.selectedColors.join(','));
      this.formData.append('sizes', this.selectedSizes.join(','));
      this.formData.append('description', this.formGroup.get('description').value);
      this.formData.append('featured', this.formGroup.get('featured').value);
      this.formData.append('top_rated', this.formGroup.get('top_rated').value);
      this.formData.append('stock', this.formGroup.get('stock').value);
      this.formData.append('isactive', this.formGroup.get('isactive').value);

      // Append each file to the FormData object
      this.files.forEach(file => {
        this.formData.append('files', file, file.name);
      });

      console.log('formData:', this.formData);

      try {
        const response = await firstValueFrom(this.apiService.postProductData(this.formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('success'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Record saved successfully');

        this.router.navigate(['/catalog/list-product']);

        setTimeout(() => {
          this.alertService.setShowAlert(false);
        }, 1500);
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.formGroup.controls).forEach(control => control.markAsTouched());
    }
  }

  onColorSelect(item: any) {
    // console.log('onColorSelect', item);
    this.selectedColors.push(item.item_text);
    console.log("Selected Colors :", this.selectedColors);
  }

  onColorDeSelect(item: any) {
    // console.log('onColorDeSelect', item);
    this.selectedColors = this.selectedColors.filter(color => color !== item.item_text);
    console.log("Deselected Colors :", this.selectedColors);
  }

  onSizeSelect(item: any) {
    // console.log('onSizeSelect', item);
    this.selectedSizes.push(item.item_text);
    console.log("Selected Sizes :", this.selectedSizes);
  }

  onSizeDeSelect(item: any) {
    // console.log('onSizeDeSelect', item);
    this.selectedSizes = this.selectedSizes.filter(size => size !== item.item_text);
    console.log("Deselected Sizes :", this.selectedSizes);
  }

}
