<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input type="text" class="form-control" id="id" formControlName="id">
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="id"><small>Auto Generated</small></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="title">Title <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="title" formControlName="title" [class.invalid]="formGroup.get('title').invalid && formGroup.get('title').touched">
                                <div class="error-message" *ngIf="formGroup.get('title').hasError('required') && formGroup.get('title').touched">
                                    Title is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="description">Description <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="description" formControlName="description" [class.invalid]="formGroup.get('description').invalid && formGroup.get('description').touched">
                                <div class="error-message" *ngIf="formGroup.get('description').hasError('required') && formGroup.get('description').touched">
                                    Description is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4">
                                <label for="page_name" class="mb-0">Page Name</label>
                            </div>
                            <div class="col-xl-10 col-sm-7">
                                <select class="form-control form-select" id="page_name" formControlName="page_name" [(ngModel)]="selectedValue" (change)="onSelectionChange($event.target.value)" [class.invalid]="formGroup.get('page_name').invalid && formGroup.get('page_name').touched" aria-label="Default select example">
                                    <option value="0" selected>Select</option>
                                    <option value="1">Home</option>
                                    <option value="2">Category</option>
                                </select>
                                <div class="error-message" *ngIf="formGroup.get('page_name').hasError('required') && formGroup.get('page_name').touched">
                                    Page Name is required.
                                </div>
                                <div class="error-message" *ngIf="formGroup.get('page_name').hasError('invalidSelect') && formGroup.get('page_name').touched">
                                    Please select Page Name.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4 ">
                                <label for="location" class="mb-0">Location</label>
                            </div>
                            <div class="col-xl-10 col-sm-7">                            
                                <select class="form-control form-select" id="location" formControlName="location" [class.invalid]="formGroup.get('location').invalid && formGroup.get('location').touched" aria-label="Default select example">
                                    <option value="0" selected>Select</option>
                                    <option value="1">Top Banner</option>
                                    <option value="2">Middle Banner</option>
                                </select>
                                <div class="error-message" *ngIf="formGroup.get('location').hasError('required') && formGroup.get('location').touched">
                                    Location is required.
                                </div>
                                <div class="error-message" *ngIf="formGroup.get('location').hasError('invalidSelect') && formGroup.get('location').touched">
                                    Please select Location.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4 ">
                                <label for="file_type" class="mb-0">Type</label>
                            </div>
                            <div class="col-xl-10 col-sm-7">
                                <select class="form-control form-select" id="file_type" formControlName="file_type" [class.invalid]="formGroup.get('file_type').invalid && formGroup.get('file_type').touched" aria-label="Default select example">
                                    <option value="0" selected>Select</option>
                                    <option value="1">Image</option>
                                    <option value="2">Video</option>
                                </select>
                                <div class="error-message" *ngIf="formGroup.get('file_type').hasError('required') && formGroup.get('file_type').touched">
                                    Type is required.
                                </div>
                                <div class="error-message" *ngIf="formGroup.get('file_type').hasError('invalidSelect') && formGroup.get('file_type').touched">
                                    Please select Type.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="redirection_url">Redirection Url <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="redirection_url" formControlName="redirection_url" [class.invalid]="formGroup.get('redirection_url').invalid && formGroup.get('redirection_url').touched">
                                <div class="error-message" *ngIf="formGroup.get('redirection_url').hasError('required') && formGroup.get('redirection_url').touched">
                                    Redirection Url is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="isactive" formControlName="isactive" data-original-title="" title="">
                                    <label for="isactive">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="add-product">
                                    <ul class="file-upload-product">
                                        <li>
                                            <div *ngIf="selectedValue == 1" class="box-input-file image-1">
                                                <input class="upload image-1" type="file" name="myFile" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-1" alt="" [src]="banner_image">
                                            </div>
                                            <div *ngIf="selectedValue == 2" class="box-input-file image-2">
                                                <input class="upload image-2" type="file" name="myFile" (change)="readFileUrl($event)">
                                                <img class="img-fluid image_zoom_1 image-2" alt="" [src]="category_banner_image">
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->